import {React, $, useEffect, useState} from 'library/base/baseContainerImports';

import Form from "library/basic/forms/Form";
import useFormInit from "./useFormInit";

/**
 * Common template for public pages
 *
 * @param formAction
 * @param formDefinitionAction
 * @param mainForm
 * @param isRequestFailed - Form submit failed with an error
 * @param title - Ex: Forgotten Your Password
 * @param subTitle - Ex: We will send you an email with instructions on how to reset your password.
 * @param errorMessage - Ex: We could not send forgot password request. Please try again.
 * @returns {*}
 * @constructor
 */


const PublicBlank = ({content}) => {

    useEffect( () => {
        initTemplate();
    }, []);

    const initTemplate = () => {
        $(".navbar").hide();
    }

    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="page-container login-container">
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content no-padding-top">
                            <div className="container">
                                {content}
                                <div className="footer text-muted">
                                    &copy; {currentYear}. <a href="https://app.motogptipping.com.au/">MotoGP Fantasy Tipping</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
;

export default PublicBlank;
